.Title {
  font-size: 32px;
  font-weight: 400;
  margin: 0 0 24px;
}

.CardRow {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.FeeSetup {
  background-color: white;
  height: 100%;
  width: 100%;
}
