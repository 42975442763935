.Wrapper {
    padding: 0 24px 24px;
    background: var(--color-white);
}

.Value {
    font-size: 20px;
}

.Label {
    font-size: 13px;
    color: var(--color-grey);
}