.Wrapper {
    min-width: 350px;
}

.Title {
    font-size: 32px;
    font-weight: 400;
    margin: 0 0 24px;
}

.CardRow {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.Key {
    color: var(--color-grey);
}

.Value {
    font-weight: 600;
    width: 60%;
    display: flex;
    justify-content: flex-end;
    text-align: end;
}
