.Wrapper div h2 {
    font-size: 32px;
}

.Link {
    font-weight: 500;
    color: var(--color-green);
    font-family: Inter, sans-serif;
    font-size: 15px;
    text-decoration: none;
}

.Margin {
    margin-right: 16px;
}
