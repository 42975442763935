:root {
    /* Fonts */
    --font-sm: 12px;
    --font-md: 13px;
    --font-lg: 15px;

    /* Colors */
    --color-black: #252c2e;
    --color-light-blue: #eef6ff;
    --color-light-grey: #fafafa;
    --color-grey: #818C99;
    --color-green: #007f39;
    --color-light-green: #cbe3cd;
    --color-header-green: #409f6b;
    --color-white: #fff;
    --color-red: #9F4040;
    --color-light-red: #f0cbcb;
    --color-light-white: #ffffff87;
    --color-background-gray: #f3f3f3;
    --color-disabled-gray: #bdbdbd;
    --color-line: #ebebeb;
}
