.ErrorIndicator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #282c34;
}

.Boom {
  font-size: 1.7rem;
}
