.Wrapper h2 {
    font-size: 32px !important;
}

.Actions {
    align-items: center;
    display: flex;
    gap: 16px;
}

.Controls {
    min-height: 80vh;
    background: #fff;
    padding: 24px;
    border-top: 1px var(--color-line) solid;
}
