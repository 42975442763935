.Wrapper {
    display: flex;
    justify-content: space-between;
    border-top: 1px var(--color-line) solid;
}

.TableStyle {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.CardStyle {
    min-width: 430px;
    border-right: 1px var(--color-line) solid;
}

.SchedulerBlock {
    width: 100%;
    margin-top: 0;
    border-bottom: 1px var(--color-line) solid;
}

.SchedulerBlock h2 {
    font-size: 32px;
    margin-top: 0;
}
